import { Paper, Typography } from "@mui/material";
import { useState } from "react";

import ActivityEditorDialog from "components/feed/ActivityEditor/ActivityEditorDialog";
import Avatar from "components/shared/Avatar";
import useMe from "hooks/useMe";
import { Audience } from "lib/motorcade/types";
import { Group } from "types/group";

type Props = {
  audience?: Audience;
  group?: Group;
};

export default function ActivityEditorCTA({ audience, group }: Props) {
  const { name, profile } = useMe();
  const [activityEditorDialogOpen, setActivityEditorDialogOpen] =
    useState(false);

  return (
    <>
      <Paper
        className="pendo_web-start-new-post"
        elevation={1}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          px: 3,
          py: 2,
          mb: 1,
        }}
        onClick={() => setActivityEditorDialogOpen(true)}
      >
        <Avatar alt={name?.fullName} src={profile?.avatar} />
        <Typography color="textSecondary" ml={2}>
          {audience === "group"
            ? "Start a discussion..."
            : "Share what's on your mind"}
        </Typography>
      </Paper>

      <ActivityEditorDialog
        initialData={audience && { audience, group }}
        open={activityEditorDialogOpen}
        onClose={() => setActivityEditorDialogOpen(false)}
      />
    </>
  );
}
