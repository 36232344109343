import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import {
  DefaultGenerics,
  FeedAPIResponse,
  RealTimeMessage,
  StreamFeed,
} from "getstream";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import useMe from "hooks/useMe";
import { StreamContext } from "lib/motorcade/providers/stream";
import {
  EnrichedResultActivity,
  FeedActivity,
  FeedGroup,
  isFeedActivity,
} from "lib/motorcade/types";
import {
  addReactionsStubToSubscribedActivities,
  prependFeedActivities,
  removePendingFeedActivities,
} from "lib/motorcade/utils/state";

const PAGE_SIZE = 50;
const SUBSCRIBE_ALLOWED: FeedGroup[] = ["public", "agg_timeline", "group"];

type SubscriptionData = {
  newActivitiesByMe: FeedActivity[];
  newActivitiesByOthers: FeedActivity[];
};

function filterSubscriptionData({
  meId,
  data,
}: {
  meId: string;
  data: RealTimeMessage;
}) {
  const { newActivitiesByMe, newActivitiesByOthers } =
    data.new.reduce<SubscriptionData>(
      (acc, activity) => {
        if (activity.actor && isFeedActivity(activity)) {
          if (activity.actor.id === meId) {
            acc.newActivitiesByMe.push(activity);
          } else {
            acc.newActivitiesByOthers.push(activity);
          }
        }
        return acc;
      },
      {
        newActivitiesByMe: [],
        newActivitiesByOthers: [],
      }
    );

  return {
    newActivitiesByMe,
    newActivitiesByOthers,
  };
}

async function enrichActivities({
  activities,
  feed,
}: {
  activities: FeedActivity[];
  feed: StreamFeed<DefaultGenerics>;
}) {
  return Promise.all(
    activities.map(async (activity) => {
      if (activity.verb === "community_expertpanel_answer") {
        try {
          const { results } = await feed.get({
            id_gte: activity.id,
            enrich: true,
            withReactionCounts: true,
            withOwnReactions: true,
            withRecentReactions: true,
            recentReactionsLimit: 2,
          });

          const retrievedActivity = results[0].activities[0];

          if (retrievedActivity.id === activity.id) {
            return retrievedActivity;
          }
        } catch (error) {
          console.error(
            `Error fetching activity with ID ${activity.id}`,
            error
          );
          return activity;
        }
      }

      return activity;
    })
  );
}

export function useFeed(feedGroup: FeedGroup, feedId: string) {
  const { id: meId } = useMe();
  const feedQueryKey = useMemo(() => {
    return `${feedGroup}:${feedId}`;
  }, [feedGroup, feedId]);
  const { streamClient, setCurrentFeedQueryKey } = useContext(StreamContext);
  const [subscribedFeeds, setSubscribedFeeds] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const [newActivities, setNewActivities] = useState<FeedActivity[]>([]);
  const feed = streamClient?.feed(feedGroup, feedId);

  const clearNewActivities = useCallback(
    () => setNewActivities([]),
    [setNewActivities]
  );

  useEffect(() => {
    setCurrentFeedQueryKey(feedQueryKey);
  }, [feedQueryKey, setCurrentFeedQueryKey]);

  const subscribe = useCallback(() => {
    const subscription = feed.subscribe(async (data) => {
      if (data.new.length === 0) return;

      await queryClient.cancelQueries({ queryKey: [feedQueryKey] });

      const { newActivitiesByMe, newActivitiesByOthers } =
        filterSubscriptionData({ meId, data });

      // Add new activity by me to feed
      if (newActivitiesByMe?.length > 0) {
        queryClient.setQueryData(
          [feedQueryKey],
          (feedData: FeedAPIResponse) => {
            const pendingRemoved = removePendingFeedActivities({ feedData });
            const newActivitiesByMeWithReactionsStub =
              addReactionsStubToSubscribedActivities(newActivitiesByMe);
            return prependFeedActivities({
              feedData: pendingRemoved,
              activities: newActivitiesByMeWithReactionsStub,
            });
          }
        );
      }

      // Add new activity by others to feed
      if (newActivitiesByOthers?.length > 0) {
        const enrichedActivities = await enrichActivities({
          activities: newActivitiesByOthers,
          feed,
        });

        setNewActivities((prev) => [...prev, ...enrichedActivities]);

        const newActivitiesByOthersWithReactionsStub =
          addReactionsStubToSubscribedActivities(enrichedActivities);

        queryClient.setQueryData(
          [feedQueryKey],
          (feedData: FeedAPIResponse) => {
            return prependFeedActivities({
              feedData,
              activities: newActivitiesByOthersWithReactionsStub,
            });
          }
        );
      }
    });

    subscription.then(
      () => {
        console.log(`Subscribed to ${feed.id}`);
      },
      (err) => {
        console.log(`Error subscribing to ${feed.id}`, err);
      }
    );
  }, [feed, feedQueryKey, meId, queryClient]);

  useEffect(() => {
    if (
      SUBSCRIBE_ALLOWED.includes(feedGroup) &&
      !subscribedFeeds.includes(feedQueryKey)
    ) {
      setSubscribedFeeds((prev) => [...prev, feedQueryKey]);
      // We should look into this more but without this delay the subscription
      // would work once and then stop working for 30 seconds and then start again
      setTimeout(subscribe, 3000);
    }
  }, [feedGroup, feedQueryKey, subscribe, subscribedFeeds]);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    ...result
  } = useInfiniteQuery({
    enabled: !!streamClient,
    refetchOnWindowFocus: false,
    queryKey: [feedQueryKey],
    queryFn: async ({ pageParam = 0 }) => {
      const activities = await feed?.get({
        limit: PAGE_SIZE,
        offset: pageParam * PAGE_SIZE,
        enrich: true,
        withReactionCounts: true,
        withOwnReactions: true,
        withRecentReactions: true,
        recentReactionsLimit: 2,
      });
      const safeActivities: FeedAPIResponse<DefaultGenerics> & {
        pageParam: number;
      } = { ...activities, pageParam };
      safeActivities.pageParam = pageParam;

      safeActivities.results = safeActivities.results.map((activity) => {
        activity?.activities?.forEach((aggActivity) => {
          if (aggActivity.latest_reactions.comment) {
            aggActivity.latest_reactions.comment =
              aggActivity.latest_reactions.comment.slice(0, 2);

            aggActivity.latest_reactions.comment.forEach((comment) => {
              if (comment.latest_children.reply) {
                comment.latest_children.reply = [];
              }
            });
          }
          return aggActivity;
        });

        return activity;
      });
      return safeActivities;
    },
    getNextPageParam: (lastPage) => !!lastPage.next && lastPage.pageParam + 1,
  });

  const activities =
    result?.data?.pages?.flatMap(
      (page) => page.results as unknown as EnrichedResultActivity
    ) || [];

  return {
    activities,
    hasNext: hasNextPage,
    isFetching: isFetching || isFetchingNextPage,
    isLoading,
    fetchNextPage,
    newActivities,
    clearNewActivities,
  };
}

export default useFeed;
