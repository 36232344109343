import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  MobileStepper,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import Link from "next/link";
import { useState } from "react";

import { getEPAccentImageURL } from "components/expert-panels/helpers";
import Close from "components/icons/Close";
import Truncate from "components/shared/Truncate";
import useExpertPanelOpenQuestions from "hooks/useExpertPanelOpenQuestions";
import useMe from "hooks/useMe";

function VisibleQuestions({ activeStep, questions }) {
  return (
    <Box sx={{ display: "grid" }}>
      {questions.map((visibleQuestion, index) => (
        <Truncate
          key={visibleQuestion.id}
          expandable={false}
          maxLines={4}
          sx={{
            gridRowStart: 1,
            gridColumnStart: 1,
            ...(index !== activeStep && {
              visibility: "hidden",
            }),
          }}
        >
          <Typography variant="h3">{visibleQuestion.question}</Typography>
        </Truncate>
      ))}
    </Box>
  );
}

export default function EPCarouselBanner() {
  const [activeStep, setActiveStep] = useState(0);

  const { name } = useMe();
  const { loading, markQuestionsAsClosed, newUnclosedQuestions } =
    useExpertPanelOpenQuestions();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));

  if (loading) return null;

  let currentQuestion = newUnclosedQuestions[activeStep];
  const showEPBanner = newUnclosedQuestions.length > 0;
  const maxSteps = newUnclosedQuestions.length ?? 0;
  const accentImageURL = getEPAccentImageURL(
    newUnclosedQuestions[activeStep]?.id
  );
  const buttonText = isXs ? "Contribute" : "Contribute to an Expert Panel";

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < maxSteps) {
      setActiveStep(nextStep);
      currentQuestion = newUnclosedQuestions[nextStep];
    }
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    if (prevStep >= 0) {
      setActiveStep(prevStep);
      currentQuestion = newUnclosedQuestions[prevStep];
    }
  };

  if (!showEPBanner) return null;

  return (
    <Card sx={{ backgroundColor: (theme) => theme.palette.grey[100], mb: 2 }}>
      <CardMedia
        alt="Placeholder"
        component="img"
        height={16}
        image={accentImageURL}
      />
      <CardHeader
        action={
          <IconButton
            aria-label="close"
            className="pendo_web-anban-expertpanels-dismiss"
            onClick={() => markQuestionsAsClosed()}
          >
            <Close fontSize="small" />
          </IconButton>
        }
        sx={{
          textAlign: "center",
          "& .MuiCardHeader-action": {
            margin: "auto 0",
            alignSelf: "center",
          },
        }}
        title={
          <Typography fontWeight={600} variant="body1">
            {name?.fullName}, you have been selected to answer an Expert Panel:
          </Typography>
        }
      />
      <CardContent
        sx={{
          textAlign: "center",
          px: 2,
          py: 0,
        }}
      >
        {newUnclosedQuestions?.length > 0 && (
          <VisibleQuestions
            activeStep={activeStep}
            questions={newUnclosedQuestions}
          />
        )}

        <Button
          LinkComponent={Link}
          className="pendo_web-anban-expertpanels-CTA"
          fullWidth={isXs}
          href={{
            pathname: `/publishing/expert-panels/${currentQuestion?.id}`,
          }}
          size="medium"
          sx={{ mt: 3 }}
          variant="contained"
        >
          {buttonText}
        </Button>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <MobileStepper
          activeStep={activeStep}
          backButton={
            <Button
              className="pendo_web-anban-expertpanels-backnext"
              disabled={activeStep === 0}
              size="small"
              startIcon={<KeyboardArrowLeft />}
              onClick={handleBack}
            >
              Back
            </Button>
          }
          nextButton={
            <Button
              className="pendo_web-anban-expertpanels-backnext"
              disabled={activeStep === maxSteps - 1}
              endIcon={<KeyboardArrowRight />}
              size="small"
              onClick={handleNext}
            >
              Next
            </Button>
          }
          position="static"
          steps={maxSteps}
          sx={{
            backgroundColor: "transparent",
            flexGrow: 1,
            justifyContent: "space-between",
            p: 1,
            width: "100%",
          }}
          variant="dots"
        />
      </CardActions>
    </Card>
  );
}
