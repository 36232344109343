import { Box } from "@mui/material";

import FeedCardLoading from "components/feed/FeedCard/FeedCardLoading";

export default function FeedLoading() {
  return (
    <>
      {Array.from({ length: 6 }, (_, i) => i + 1).map((i) => (
        <Box key={i} sx={{ mb: 2 }}>
          <FeedCardLoading withMedia={i % 2 === 0} />
        </Box>
      ))}
    </>
  );
}
