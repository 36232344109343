import { RefObject, useCallback, useEffect, useMemo, useRef } from "react";

import Indicator from "components/shared/Indicator";
import useIsVisible from "hooks/useIsVisible";
import { FeedActivity } from "lib/motorcade/types";

type Props = {
  trackerRef: RefObject<Element>;
  newActivities: FeedActivity[];
  clearNewActivities: () => void;
};

export default function NewActivityIndicator({
  trackerRef,
  newActivities,
  clearNewActivities,
}: Props) {
  const mainLayoutElement = useRef(null);
  mainLayoutElement.current = window.document.querySelector("#main-content");

  const isVisible = useIsVisible(trackerRef);

  const handleScrollUp = () => {
    mainLayoutElement?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    clearNewActivities();
  };

  const handleScroll = useCallback(
    (e) => {
      if (e.target.scrollTop === 0) {
        clearNewActivities();
      }
    },
    [clearNewActivities]
  );

  useEffect(() => {
    if (mainLayoutElement?.current !== null) {
      mainLayoutElement?.current?.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (mainLayoutElement?.current !== null) {
        mainLayoutElement?.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isVisible && newActivities?.length > 0) {
      clearNewActivities();
    }
  }, [clearNewActivities, isVisible, newActivities]);

  const avatars = useMemo(() => {
    const avatarsSet = newActivities.reduce((acc, curr) => {
      if (curr?.actor?.data?.avatar) {
        acc.add(curr?.actor?.data?.avatar);
      }
      return acc;
    }, new Set<string>());
    return [...avatarsSet];
  }, [newActivities]);

  if (!avatars || avatars?.length === 0 || isVisible) return null;

  return (
    <Indicator
      avatars={avatars}
      handleClick={handleScrollUp}
      label="New activity"
    />
  );
}
