import { RefObject, useEffect, useRef, useState } from "react";

export function useIsVisible(elementRef: RefObject<Element>) {
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsVisible(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    if (observerRef?.current && elementRef?.current) {
      observerRef.current.observe(elementRef.current);
    }

    return () => {
      if (observerRef?.current) {
        observerRef.current.disconnect();
      }
    };
  }, [elementRef]);

  return isVisible;
}

export default useIsVisible;
