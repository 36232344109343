import { Straight } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";

type Props = {
  avatars: string[];
  label: string;
  handleClick: () => void;
};

export default function Indicator({ avatars, label, handleClick }: Props) {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        margin: "0 auto",
        zIndex: (theme) => theme.zIndex.fab,
        px: 1,
        py: 0.75,
        borderRadius: "30px",
        backgroundColor: "primary.main",
        display: "flex",
        alignItems: "center",
        gap: 1,
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Straight sx={{ fontSize: 20, color: "#fff" }} />
      <Typography sx={{ color: "#fff" }} variant="body2Semibold">
        {label}
      </Typography>
      <AvatarGroup
        max={3}
        spacing="medium"
        sx={{
          "& .MuiAvatar-root": {
            width: 24,
            height: 24,
            fontSize: 15,
            ml: -0.5,
          },
        }}
      >
        {avatars.map((avatar) => {
          return <Avatar key={avatar} src={avatar} />;
        })}
      </AvatarGroup>
    </Box>
  );
}
